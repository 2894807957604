.App {
  text-align: center;
}

.header {
  position: relative;
  height: 60vh; /* Adjust the height based on your preference */
  overflow: hidden;
  background: url('https://images.unsplash.com/photo-1630931226611-35cd35ba9917?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fGJsYWNrJTIwYW5kJTIwd2hpdGUlMjB3b21lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=400&q=60') center bottom/cover no-repeat; /* Adjust the URL */
}


.header h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #b80000; /* Deep red for a classy look */
  font-size: 8rem;
  cursor: pointer;
}

.header h1::before {  
  transform: scaleX(0);
  transform-origin: bottom right;
}

.header h1:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.header h1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  inset: 0 0 0 0;
  background: #e5e4e4;
  z-index: -1;
  transition: transform .3s ease;
}

.header img:hover {
  transform: scale(1.2); /* Apply scale transformation on hover */
}

.header img {
  transition: transform .4s;
  cursor: pointer;
}


@media (max-width: 1300px) {
  .header h1 {
    font-size: 7em;
  }
}

@media (max-width: 700px) {
  .header h1 {
    font-size: 5em;
  }
  .lipstick-list li{
    font-size: 0.7em;
  }
  .lipstick-name {
    margin-top: 20px;
    font-weight: bold;
    font-size: 0.7em;
  }
}

h2 {
  text-align: center;
}

.align-search {
  display: flex;
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
}

.align-search-next {
  display: flex;
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
}

.lipstick-search input {
  width: 100%;
  padding : 15px;
  border-radius: 25px;
  border: none;
  box-shadow: 0 4px 6px rgba(35, 30, 30, 0.1);
  font-size: 1.2em;
  margin: 0 auto;
}

.lipstick-search{
  padding: 20px;
  width: 50%;
  text-align: center;
}

.lipstick-list {
  margin: 0% 5% 0% 5%;
  display: flex;
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
  padding: 0;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .lipstick-list {
    margin: 0%;
    display: flex;
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
    padding: 0;
    flex-wrap: wrap;
  }
}


.lipstick-list li {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  margin: 1%;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
  background: #b80000;
  border: none;
  z-index: 1;
}

.lipstick-list li::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 5px;
  background-color: #eaf818;
  background-image: linear-gradient(315deg, #e5e4e4 0%, #923227 74%);
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5);
 transition: all 0.3s ease;
}

.lipstick-list li:hover {
  color: #000;
}

.lipstick-list a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
}

.lipstick-list li:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.lipstick-list li:active {
  top: 2px;
}


/* ProductListPage.css */

body {
  font-family: 'Arial', sans-serif;
  background-color: #e5e4e4;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  color: #923227;
  text-align: center;
}

.back-button {
  display: flex;
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
}

.product-list {
  display: flex;
  align-items: center; /* Vertical alignment */
  justify-content: center; /* Horizontal alignment */
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.product-list li {
  width: 20%;
  margin: 10px;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.product-list li:hover {
  transform: scale(1.05);
}

.product-list img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.lipstick-name {
  margin-top: 30px;
  font-weight: bold;
}

.brand-link {
  width: 50%;
  display: block;
  width:10%;
  margin: 20px;
  text-align: center;
  border-radius: 50px;
  padding: 20px;
  background-color: #923227;
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
}

.brand-link:hover {
  background-color: #b80000;
}
